import React from "react"
import styled from "styled-components"
import { down } from "styled-breakpoints"
import { StaticImage } from "gatsby-plugin-image"
//DATA
//STYLES
import { H2, LabelBW, Text } from "../utils/typo"
import { ContainerMob, Section, Pattern } from "../utils/utils"
//COMPONENTS
import Seo from "../components/seo/SEO"
import Layout from "../layout/layout"

const Lines = styled(Pattern)`
  height: 50vh;
  position: relative;
  z-index: -10;
  margin-top: -25vh;
`

const Wrapper = styled(ContainerMob)`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 4rem;
  overflow: hidden;
  align-items: center;

  ${down("sm")} {
    grid-template-columns: 1fr;
  }
`

// const Grid112 = styled(ContainerMob)`
//   display: grid;
//   grid-template-columns: 1fr 1fr 2fr;
//   div {
//     background-color: ${props => props.bgcolor || props.theme.color.grey5};
//   }
// `

// const Grid211 = styled(Grid112)`
//   grid-template-columns: 2fr 1fr 1fr;
// `

// const TextWrapper = styled.div`
//   background-color: transparent !important;
//   padding: 2rem;
// `

const InfoWrapper = styled.div`
  //padding: 2rem;
`

const Onas = () => {
  return (
    <div>
      <Seo title="O nas" slug="/onas" />
      <Layout>
        <Section
          pt="6rem"
          pb="3rem"
          data-sal="fade"
          data-sal-delay="100"
          data-sal-duration="600"
        >
          <Wrapper>
            <InfoWrapper>
              <LabelBW mb="2rem">O nas</LabelBW>
              <H2 mb="1rem" lineHeight="1.1">
                IKS DRUK — drukarnia cyfrowa wielkoformatowa i małoformatowa
              </H2>
              <Text weight="700">
                — Jesteśmy prężnie rozwijającą się firmą świadczącą kompleksowe
                usługi poligraficzne i projektowe.
              </Text>
              <Text mt="2rem">
                Nasza drukarnia zajmuje się cyfrowym drukiem mało- i
                wielkoformatowym. Dzięki zastosowaniu nowoczesnej i wydajnej
                infrastruktury sprzętowej możemy się szczycić wysoką jakością
                druku oraz terminowością nawet w przypadku zaawansowanych i
                rozbudowanych plików graficznych. Stale inwestujemy w rozwój
                firmy zwiększając park maszynowy oraz asortyment mediów
                drukarskich i usług poligraficznych.
              </Text>
            </InfoWrapper>

            <StaticImage
              placeholder="blurred"
              alt="Projektowanie graficzne - ikona"
              src="../assets/images/komputer.png"
              layout="fullWidth"
              objectFit="contain"
              aspectRatio="1"
            />
          </Wrapper>

          <Lines />
        </Section>

        {/* <Section>
          <Grid112>
            <StaticImage
              placeholder="blurred"
              alt="Projektowanie graficzne - ikona"
              src="../assets/images/archi.png"
              layout="fullWidth"
              objectFit="contain"
              aspectRatio="1"
            />
            <StaticImage
              placeholder="blurred"
              alt="Projektowanie graficzne - ikona"
              src="../assets/images/komputer.png"
              layout="fullWidth"
              objectFit="contain"
              aspectRatio="1"
            />
            <TextWrapper>
              <Text>Tekst jakiś mądry</Text>
            </TextWrapper>
          </Grid112>
          <Grid211>
            <Text>Tekst jakiś mądry</Text>
            <StaticImage
              placeholder="blurred"
              alt="Projektowanie graficzne - ikona"
              src="../assets/images/komputer.png"
              layout="fullWidth"
              objectFit="contain"
              aspectRatio="1"
            />
            <StaticImage
              placeholder="blurred"
              alt="Projektowanie graficzne - ikona"
              src="../assets/images/komputer.png"
              layout="fullWidth"
              objectFit="contain"
              aspectRatio="1"
            />
          </Grid211>
        </Section> */}
      </Layout>
    </div>
  )
}
export default Onas
